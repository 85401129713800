import Header from "../../../components/header"
import React from "react"
import { Link } from "gatsby"

const RedirectSubmit = () => {
  return (
    <div>
      <Header />

      <div className="flex h-screen  justify-center items-center flex-col">
        <div className="text-2xl  ">
          <p>Instrument Submitted</p>
        </div>

        <div className=" pt-2">
          <Link to={`/app/instrument`}>
            <button
              type="button"
              className="  px-4 py-2 border border-gray-300 rounded-md  font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Proceed
            </button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default RedirectSubmit
